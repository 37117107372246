<template>
  <div>
    <div class="header-topic">
      <div class="row">
        <div id="exam" class="col-3">
          <a href="" @click.prevent="$emit('onTabChange','exam')">
            <div class="exam">
              <div class="exam-icon">
                <img src="images/img/exam.png" alt="" />
              </div>
              <div class="exam-content">
                <h1>Exam</h1>
                <p>3891 Ranchview Dr. Richardson, California</p>
              </div>
            </div>
          </a>
        </div>

        <div class="col-3 pl-0">
          <a href="" @click.prevent="$emit('onTabChange','billing')">
            <div class="exam">
              <div style="background-color: #97c250" class="exam-icon">
                <img src="images/img/bill.png" alt="" />
              </div>
              <div class="exam-content">
                <h1>Billing</h1>
                <p>3891 Ranchview Dr. Richardson, California</p>
              </div>
            </div>
          </a>
        </div>

        <div class="col-3 pl-0">
          <a href="" @click.prevent="$emit('onTabChange','library')">
            <div class="exam">
              <div style="background-color: #f389f5" class="exam-icon">
                <img src="images/img/library.png" alt="" />
              </div>
              <div class="exam-content">
                <h1>Library</h1>
                <p>3891 Ranchview Dr. Richardson, California</p>
              </div>
            </div>
          </a>
        </div>

        <div class="col-3 pl-0 pr-0">
          <a href="" @click.prevent="$emit('onTabChange','activity')">
            <div class="exam">
              <div style="background-color: #50aec2" class="exam-icon">
                <img src="images/img/exam.png" alt="" />
              </div>
              <div class="exam-content">
                <h1>Activity Report</h1>
                <p>3891 Ranchview Dr. Richardson, California</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
};
// selecting all btns
const callToActionBtns = document.querySelectorAll(".mobile__CTA--btn");

// looping through each btn and toggling the action class
callToActionBtns.forEach((btn) => {
  btn.addEventListener("click", (e) => {
    callToActionBtns.forEach((el) => el.classList.remove("active"));
    e.target.classList.toggle("active");
  });
});
</script>
    <style lang="scss">
</style>
    